<template>
  <datatable
    :columns="columns"
    :scroll-x="false"
    :total="orders.meta.page.total"
    :perPage="orders.meta.page.perPage"
    :currentPage="orders.meta.page.currentPage"
    :meta="orders.meta"
    cursor
    @pagechanged="onPageChanged"
  >
    <template v-slot:tbody="{ classes }">
      <tr
        v-for="order in orders.data"
        :key="order.id"
        :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
        @click="onClickDetail(order)"
      >
        <td :class="[classes.td]">
          <span class="block font-bold text-gray-900">{{
            order.attributes.origin_code
          }}</span>
          <span class="text-xs text-gray-500">{{
            order.attributes.updatedAt | formatDate
          }}</span>
        </td>
        <td :class="[classes.td]">
          {{
            getSingleIncluded(
              orders,
              order.relationships['destination-office'].data.id
            ).attributes.code
          }}
        </td>
        <td :class="[classes.td]">
          {{
            getSingleIncluded(
              orders,
              order.relationships['destination-office'].data.id
            ).attributes.name
          }}
        </td>
        <td :class="[classes.td]">
          {{
            getSingleIncluded(
              orders,
              order.relationships['destination-warehouse'].data.id
            ).attributes.code
          }}
          /
          {{
            getSingleIncluded(
              orders,
              order.relationships['origin-warehouse'].data.id
            ).attributes.code
          }}
        </td>
        <td :class="[classes.td]">
          {{
            getSingleIncluded(orders, order.relationships['order'].data.id)
              .attributes.area_name
          }}
        </td>
        <td :class="[classes.td]">
          {{
            getSingleIncluded(orders, order.relationships['order'].data.id)
              .attributes.grand_total_price_after_ppn
          }}
        </td>
        <td :class="[classes.td]">
          <base-badge color="indigo">{{
            getSingleIncluded(orders, order.relationships['order'].data.id)
              .attributes.warehouse_status
          }}</base-badge>
        </td>
      </tr>
    </template>
  </datatable>
</template>

<script>
export default {
  props: {
    orders: {
      type: Object,
      required: true
    },
    destinationOfficeLabel: {
      type: String,
      default: 'Stockist'
    }
  },
  emits: ['change-page', 'click-detail'],
  computed: {
    columns() {
      return [
        { id: 'origin_code', name: 'Nomor Faktur' },
        { id: 'destination_office_code', name: `Kode ${this.destinationOfficeLabel}` },
        { id: 'destination_office_name', name: 'Nama Pemilik' },
        { id: 'origin_warehouse_code', name: 'Kode Gudang	' },
        { id: 'area', name: 'Wilayah Harga' },
        { id: 'grand_total_price_after_ppn', name: 'Total Penjualan' },
        { id: 'status', name: 'Status Pengeluaran' },
      ];
    },
  },
  methods: {
    onPageChanged(page) {
      this.$emit('change-page', page)
    },
    onClickDetail(simpleOrder) {
      this.$emit('click-detail', simpleOrder)
    }
  }
};
</script>
