<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Pengeluaran Barang ke Kantor Pelayanan">
      <div class="flex items-center justify-end space-x-2">
        <div class="relative mt-1 rounded-md shadow-sm">
          <base-select
            v-model="order_status"
            @change="filterByShipped"
            :options="[
              {
                label: 'Semua',
                value:
                  'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
              },
              {
                label: 'Diproses Gudang Pengiriman',
                value: 'Diproses Gudang Pengiriman',
              },
              {
                label: 'Persiapan',
                value: 'Persiapan',
              },
              {
                label: 'Diproses Gudang Pengeluaran',
                value: 'Diproses Gudang Pengeluaran',
              },
            ]"
          />
        </div>
        <base-search v-model="search" @input="debounceSearch" />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <PengeluaranTable
            view-as="kantor-pelayanan"
            @sort="handleSort"
            @change="onPageChangeOrder"
            @click-row="redirectToDetail"
          />
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import PengeluaranTable from '@/components/pengeluaran/pengeluaran-table.vue';
import { getRelationships } from '@/services/utils.service';
import BaseSearch from '@/components/base/Search.vue';
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'Home',
  components: {
    PengeluaranTable,
    BaseSearch,
    BaseCard,
  },
  data() {
    return {
      search: null,
      openDetail: false,
      is_packed: false,
      order_status:
        'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
      dataPenjualan: {},
      dataBrgMasuk: {},
      detailBrgMasuk: {},
      cancelConfirmation: false,
      sort: {
        createdAt: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    orderParams() {
      return {
        'filter[origin_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'page[limit]': 5,
        'filter[order_status]': this.order_status,
        'filter[destination_office_category_id]': 2,
        include: 'destination-office,origin-warehouse,origin-office,order',
        fields: {
          orders: 'area_name,grand_total_price_after_ppn,updatedAt',
          offices: 'code,name',
          'simple-orders':
            'origin_code,destination_code,destination-office,origin-office,order_id,order,order_status,warehouse_status',
        },
      };
    },
  },
  methods: {
    getRelationships,
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    handleSort({ currentState, column } = {}) {
      this.sort[column] = !this.sort[column];

      this.fetchOrders({
        sort: !currentState ? column : `-${column}`,
        ...this.orderParams,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...page,
        ...this.orderParams,
      });
    },
    filterByShipped() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    redirectToDetail(data) {
      this.$router.push(`barang-ke-kp/${data.attributes.order_id}`);
    },
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
