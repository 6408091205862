<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="value"
      style="animation-duration: 0.3s"
      class="fixed inset-0 z-10 mx-4"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
        >
          <div class="mb-8 flex justify-between">
            <div class="flex items-center space-x-4">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Detail Order Barang
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Data Order Barang
                </p>
              </div>
            </div>
            <div>
              <img
                src="@/assets/images/logo.png"
                alt=""
                class="flex justify-end"
              />
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-sm font-bold text-gray-700">Nama Koli</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ packet.name }}
                </dd>
              </div>
              <div class="w-full">
                <dt class="text-sm font-bold text-gray-700">Kode Koli</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ packet.code }}
                </dd>
              </div>
            </dl>
            <dl class="mt-6 flex flex-row gap-x-4">
              <div class="w-full">
                <dt class="text-sm font-bold text-gray-700">Daftar Produk</dt>
              </div>
            </dl>
            <div class="my-6 sm:col-span-6">
              <Datatable :paginated="false" :footer="false" class="mt-4">
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        KODE BARANG
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        JUMLAH
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        HARGA
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        BERAT
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        TOTAL HARGA
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        TOTAL BERAT
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="bg-white"
                      v-for="(barang, index) in packet.products"
                      :key="index"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ barang.product_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.shipped_product_qty }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.product_price | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.product_weight }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{
                          (barang.product_price * barang.shipped_product_qty)
                            | toCurrency
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                      >
                        {{ barang.total_weight * barang.shipped_product_qty }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
            <div class="flex">
              <div class="flex w-2/3 flex-col gap-y-2"></div>
              <div class="flex w-1/3 flex-col gap-y-2">
                <div class="inline-flex w-full justify-between"></div>
                <div class="inline-flex w-full justify-between"></div>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              @click="onClose"
              type="button"
              class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PacketDetailModal',
  props: {
    value: {
      type: Boolean,
    },
    packet: {
      type: Object,
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    // Your child components here
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
