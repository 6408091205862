<template>
  <BaseCard
    v-if="stockMovements?.length"
    title="Informasi Kemasan"
    description="Daftar kemasan yang akan dikirim ke Stockist"
  >
    <div>
      <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
        <div class="sm:col-span-10">
          <base-input
            v-if="stockMovements"
            :value="getOrder.data.attributes.code"
            fullwidth
            inset
            disabled
            label="Nama Kemasan"
          />
        </div>
        <div
          class="sm:col-span-1"
          v-if="!getOrder.data.attributes.is_valid_for_shipment"
        >
          <button
            @click="
              $router.push(
                `/gudang/pengeluaran/barang-ke-stockist/${getOrder.data.id}/pengemasan`
              )
            "
            class="field-inset-default h-full w-full border border-gray-500 text-gray-500"
          >
            <div class="flex items-center justify-center py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              <p class="ml-2 text-xs text-gray-400">Edit</p>
            </div>
          </button>
        </div>
        <div
          :class="[
            getOrder.data.attributes.is_valid_for_shipment
              ? 'sm:col-span-2'
              : 'sm:col-span-1',
          ]"
        >
          <button
            @click="onPrintStockMovement"
            class="field-inset-default h-full w-full border border-gray-500 text-gray-500"
          >
            <div class="flex items-center justify-center py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <p class="ml-2 text-xs text-gray-400">Cetak</p>
            </div>
          </button>
        </div>
      </div>
      <Datatable :paginated="false" :footer="false" class="mt-2">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kode Koli
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah Produk
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Aksi
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              class="bg-white"
              v-for="(koli, index) in stockMovements.attributes.packets"
              :key="index"
            >
              <td
                @click="detailModal(koli)"
                class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{ koli.name }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ koli.products.length }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                <div class="m-auto flex space-x-2">
                  <!-- print koli -->
                  <button type="button" @click="onPrintPacket(index)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InformasiKemasan',
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    stockMovements() {
      return this.getOrder.data.relationships['stock-movements'].data[0]
        ? this.getRelationships(
            this.getOrder,
            this.getOrder.data.relationships['stock-movements'].data.map((it) =>
              this.getOrder.included.find((include) => include.id == it.id)
            )
          )
        : null;
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    detailModal(val) {
      this.$emit('click-row', val);
    },
    onPrintPacket(val) {
      this.$emit('print-packet', val);
    },
    onPrintStockMovement(val) {
      this.$emit('print-stock-movement', val);
    },
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
