var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end gap-x-2"},[_c('base-input',{attrs:{"label":"Filter Status"}},[_c('base-select',{attrs:{"shadow":false,"options":[
            {
              label: 'Semua',
              value:
                'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
            },
            {
              label: 'Diproses Gudang Pengiriman',
              value: 'Diproses Gudang Pengiriman',
            },
            {
              label: 'Persiapan',
              value: 'Persiapan',
            },
            {
              label: 'Diproses Gudang Pengeluaran',
              value: 'Diproses Gudang Pengeluaran',
            },
          ]},on:{"change":_vm.onFilter},model:{value:(_vm.filter.order_status),callback:function ($$v) {_vm.$set(_vm.filter, "order_status", $$v)},expression:"filter.order_status"}})],1),_c('base-input',{attrs:{"shadow":false,"placeholder":"Cari Kode atau Nama","type":"search","debounce":"","label":"Cari"},on:{"native-input":_vm.onFilter},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('pengeluaran-table',{attrs:{"orders":_vm.orders},on:{"change-page":_vm.onChangePage,"click-detail":_vm.onClickDetail}})],1),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }