<template>
  <div class="mx-auto px-4 sm:px-4 lg:px-8">
    <div class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="">
        <div class="flex flex-col">
          <div class="flex items-center justify-end space-x-2">
            <div class="ounded-md relative shadow-sm">
              <div>
                <select
                  v-model="filter.isReceived"
                  @change="handleFilter"
                  class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                >
                  <option :value="null">Semua</option>
                  <option :value="true">Diterima</option>
                  <option :value="false">Belum diterima</option>
                </select>
              </div>
            </div>
            <base-search v-model="filter.search" @input="handleSearch" />
            <base-button @click="visiblePengeluaranClassificationModal = true">
              Tambah Barang
            </base-button>
          </div>
          <div class="flex mt-2 sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getStockMovements.meta.page.total"
                :perPage="getStockMovements.meta.page.perPage"
                :currentPage="getStockMovements.meta.page.currentPage"
                @pagechanged="handleChangePage"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nama Kantor
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nama Gudang
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Produk
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <div v-if="isLoading" class="flex items-center text-center">
                    <loading></loading>
                  </div>
                  <tbody v-if="getStockMovements.data?.length > 0">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getStockMovements.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="handleClickItem(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.origin_office_name }}
                        / {{ data.attributes.destination_warehouse_name }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.origin_warehouse_name }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.relationships['movement-details'].data.length }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="bg-white">
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        colspan="8"
                      >
                        Data tidak ditemukan
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>

          <stock-movement-modal
            :visible="visibleDetail"
            description="Data penerimaan barang dari Stockist"
            :with-dispute="false"
            force-display-first-packet
            @close="visibleDetail = false"
            @refresh="handleRefresh"
            with-pilah-barang
            @pilah-barang="onStockClassification"
          >
            <template #origin-office="{ originOffice }">
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nomor Stockist</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ originOffice.attributes.code }}
                </dd>
              </div>
            </template>
          </stock-movement-modal>
          <PengeluaranClassificationModal
            :visible="visiblePengeluaranClassificationModal"
            @close="onStockClassification"
            @refetch="onRefetch"
            perusahaan
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import { sum } from '@/utils/array';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import PengeluaranClassificationModal from '@/components/modal/pengeluaran-classification-modal.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    StockMovementModal,
    BaseSearch,
    PengeluaranClassificationModal,
    BaseButton,
  },

  data() {
    return {
      visibleDetail: false,
      visiblePengeluaranClassificationModal: false,
      page: {
        number: 1,
        size: 5,
      },
      filter: {
        isReceived: false,
        search: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      isLoading: 'stock_movements/getLoading',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    async handleClickItem(item) {
      const res = await this.fetchStockMovement({ id: item.id });

      if (res) {
        this.visibleDetail = true;
      }
    },
    handleChangePage(page) {
      this.page.number = page;

      this.loadStockMovements({ resetPage: false });
    },
    handleSearch: debounce(function () {
      this.handleFilter();
    }, 300),
    handleFilter() {
      this.loadStockMovements();
    },
    handleRefresh() {
      this.resetFilter();
      this.loadStockMovements();

      this.visibleDetail = false;
    },
    resetPage() {
      this.page.size = 5;
      this.page.number = 1;
    },
    resetFilter() {
      this.filter.isReceived = false;
      this.filter.search = null;
    },
    countStockMovementWeight(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }
      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.total_weight);
      });
    },
    countStockMovementPrice(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }
      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.product_price);
      });
    },
    loadStockMovements({ resetPage = true } = {}) {
      if (resetPage) {
        this.resetPage();
      }

      this.fetchStockMovements({
        origin_office_category: 'Pusat',
        destination_office_category: 'Stockist',
        include: 'movement-details',
        // 'filter[is_shipped]': true,
        // 'filter[is_received]': this.filter.isReceived,
        // 'filter[search]': this.filter.search,
        'page[size]': this.page.size,
        'page[number]': this.page.number,
        sort: '-createdAt',
        'filter[movement_type]': 'classify-stocks',
        // 'fields[stock-movements]': 'code,order_code,createdAt,product_summaries'
      });
    },
    onStockClassification({ allModal } = {}) {
      if (allModal) {
        this.visibleDetail = false;
        this.visiblePengeluaranClassificationModal = false;
        return;
      }
      this.visibleDetail = !this.visibleDetail;
      this.visiblePengeluaranClassificationModal =
        !this.visiblePengeluaranClassificationModal;
    },
    onRefetch() {
      this.loadStockMovements();
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
