<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="visible"
      style="animation-duration: 0.3s"
      class="fixed inset-0 z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
        >
          <form @submit.prevent="submit">
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Batalkan Faktur Pengemasan
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Sudah ada faktur pengemasan. Anda yakin ingin membatalkan?
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <button
                type="submit"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="close"
                type="button"
                class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'CancelStockMovementConfirmationModal',
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    value: null,
  },
  computed: {
    inputVal: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('cancel');
    },
  },
};
</script>
