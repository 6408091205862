<template>
  <Datatable
    :total="getOrders.meta.page.total"
    :perPage="getOrders.meta.page.perPage"
    :currentPage="getOrders.meta.page.currentPage"
    :meta="getOrders.meta"
    @pagechanged="onPageChangeOrder"
    cursor
    :is-empty="!getOrders.data?.length"
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="flex cursor-pointer justify-between px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            No. Faktur
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode {{ mapOfficeCategories[viewAs] }}
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nama Pemilik
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Kode Gudang
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Wilayah Harga
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Total Penjualan
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Status Pengeluaran
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <div v-if="isLoading" class="flex items-center text-center">
        <loading></loading>
      </div>
      <tbody v-if="getOrders.data?.length > 0">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getOrders.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click="onClickRow(data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <order-code-column :value="data" />
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              data.relationships['destination-office'].data
                ? getRelationships(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.code
                : null
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              data.relationships['destination-office'].data
                ? getRelationships(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.name
                : null
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              data.relationships['destination-office'].data
                ? getRelationships(
                    getOrders,
                    data.relationships['destination-office'].data.id
                  ).attributes.code
                : null
            }}
            /
            {{
              data.relationships['origin-office'].data
                ? getRelationships(
                    getOrders,
                    data.relationships['origin-office'].data.id
                  ).attributes.code
                : null
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              getSingleIncluded(getOrders, data.relationships.order.data.id)
                .attributes.area_name
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getSingleIncluded(getOrders, data.relationships.order.data.id)
                .attributes.grand_total_price_after_ppn | toCurrency
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <span
              class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
            >
              {{ data.attributes.warehouse_status }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import Datatable from '@/components/base/Datatable';
import { mapGetters } from 'vuex';
import { getRelationships } from '@/services/utils.service';
import OrderCodeColumn from '@/components/order/order-code-column.vue';

export default {
  name: 'PengeluaranTable',
  props: {
    viewAs: {
      type: String,
      default: 'stockist',
    },
  },
  components: {
    Datatable,
    OrderCodeColumn,
  },
  data() {
    return {
      mapOfficeCategories: {
        stockist: 'Stockist',
        'kantor-pelayanan': 'Kantor Pelayanan',
        'stockist-center': 'Stockist Center',
        member: 'Mitra Usaha',
      },
    };
  },
  computed: {
    ...mapGetters({
      getStockMovements: 'stock_movements/getStockMovements',
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
  },

  methods: {
    getRelationships,
    onPageChangeOrder(pageNumber) {
      this.$emit('change', pageNumber);
    },
    handleSort() {},
    onClickRow(data) {
      this.$emit('click-row', data);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
