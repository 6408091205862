<template>
  <Modal
    :showing="visible"
    @close="visible = false"
    :showClose="true"
    :backgroundClose="true"
    size="max-w-sm"
  >
    <div>
      <div
        class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-red-600"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <slot name="heading">
          {{ message }}
        </slot>
      </div>
    </div>
    <div class="mt-5 sm:mt-6">
      <button
        name="modal-selesai"
        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
        @click="visible = false"
      >
        Selesai
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from './BaseModal.vue';

export default {
  name: 'BaseErrorModal',
  components: { Modal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: null,
  },
  computed: {
    visible: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
