var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseModal',{attrs:{"overflowable":"","showing":_vm.visible,"showClose":_vm.visible,"backgroundClose":true,"size":"max-w-5xl"},on:{"close":_vm.onClose}},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg font-bold leading-6 text-gray-900"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"mt-1 max-w-2xl text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',[_c('img',{staticClass:"w-32",attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})])]),_c('div',{staticClass:"relative"},[_c('base-input',{attrs:{"type":"text","label":"Kode Kantor","id":"code","placeholder":"Masukkan kode kantor","inset":"","with-label":"","fullwidth":""},on:{"blur":_vm.handleBlurOffice},model:{value:(_vm.selectedOffice.attributes.code),callback:function ($$v) {_vm.$set(_vm.selectedOffice.attributes, "code", $$v)},expression:"selectedOffice.attributes.code"}}),_c('button',{staticClass:"absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3",attrs:{"type":"button"},on:{"click":_vm.toggleOfficeModal}},[_c('Icon',{staticClass:"h-5 w-5 text-gray-500 hover:text-gray-700",attrs:{"icon":"heroicons:magnifying-glass-circle-20-solid"}})],1)],1),_c('Datatable',{staticClass:"mt-8",attrs:{"withOverflow":"","paginated":false,"footer":false},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',{staticClass:"bg-gray-50"},[_c('tr',_vm._l(([
                {
                  label: 'Kode',
                },
                {
                  label: 'Nama',
                },
                {
                  label: 'Status',
                },
                {
                  label: 'Jumlah',
                  classes: 'text-right',
                },
                {
                  label: 'Aksi',
                  classes: 'text-right',
                },
              ]),function(item){return _c('th',{key:item.label,staticClass:"whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500",class:item.classes,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',[_vm._l((_vm.movementDetails),function(movementDetail,i){return [_c('tr',{key:i},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500"},[_c('div',{staticClass:"relative",staticStyle:{"width":"fit-content"}},[_c('base-select-search',{attrs:{"is-loading":false,"data":_vm.getStocks.data ?? [],"label":"product_code","placeholder":"Cari Kode"},on:{"infinite-scroll":_vm.handleInfiniteScroll,"search":_vm.handleSearch,"change":_vm.handleChange},model:{value:(movementDetail.product_id),callback:function ($$v) {_vm.$set(movementDetail, "product_id", $$v)},expression:"movementDetail.product_id"}})],1)]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500",staticStyle:{"max-width":"200px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"title":movementDetail?.product_id.attributes?.product_name}},[_vm._v(" "+_vm._s(movementDetail?.product_id.attributes?.product_name)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_c('base-select',{attrs:{"options":[
                    {
                      label: 'Pilih Status',
                      value: null,
                    },
                    {
                      label: 'Layak Dijual',
                      value: 'suitable_for_sale',
                    },
                    {
                      label: 'Tidak Layak Dijual',
                      value: 'unsuitable_for_sale',
                    },
                    {
                      label: 'Expired',
                      value: 'expired',
                    },
                    {
                      label: 'Rusak',
                      value: 'damaged',
                    },
                  ]},model:{value:(movementDetail.product_status),callback:function ($$v) {_vm.$set(movementDetail, "product_status", $$v)},expression:"movementDetail.product_status"}})],1),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(movementDetail.product_qty),expression:"movementDetail.product_qty"}],ref:"jumlah_barang",refInFor:true,staticClass:"block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm",attrs:{"type":"text","name":"jumlah_barang","placeholder":"Jumlah"},domProps:{"value":(movementDetail.product_qty)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(movementDetail, "product_qty", $event.target.value)}}})]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.movementDetails.splice(i, 1)}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:x-mark-20-solid"}})],1)])])]})],2)]},proxy:true}])}),_c('div',{staticClass:"mt-8 flex justify-between"},[_c('div',[_c('base-button',{attrs:{"color":"white","type":"button"},on:{"click":function($event){return _vm.movementDetails.push({
              product_id: {},
              product_qty: 0,
              product_status: null,
            })}}},[_vm._v(" Tambah Produk ")])],1),_c('base-button',{attrs:{"type":"button","disabled":!_vm.selectedOffice.id || !_vm.selectedWarehouse.id},on:{"click":_vm.handleSave}},[_vm._v(" Submit ")])],1)],1),_c('OfficeModal',{attrs:{"is-visible":_vm.visibleOfficeModal},on:{"onSearchKantor":_vm.searchOffice,"onPageChangeKantor":_vm.onPageChangeOffice,"change-office":_vm.changeOffice,"closeKantorModal":() => (_vm.visibleOfficeModal = !_vm.visibleOfficeModal)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }