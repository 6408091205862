<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="space-y-4" v-else>
      <OrderInformation view-as="stockist-center" />

      <InformasiKemasan
        @click-row="detailModal"
        @print-packet="onPrintPacket"
        @print-stock-movement="onPrintStockMovement"
      />

      <InformasiBarangKeluar />

      <div class="py-8">
        <div class="flex justify-between">
          <div v-if="dataPenjualan.faktur_pengemasan">
            <div class="flex space-x-2">
              <base-button
                color="transparent"
                v-if="
                  !dataPenjualan.faktur_pengemasan.attributes
                    .is_valid_for_shipment
                "
                @click="cancelConfirmation = !cancelConfirmation"
                type="button"
              >
                Batalkan Faktur Penjualan
              </base-button>
              <base-button
                color="transparent"
                v-if="
                  dataPenjualan.faktur_pengemasan &&
                  !dataPenjualan.faktur_pengemasan.attributes
                    .is_valid_for_shipment
                "
                @click="
                  cancelStockMovementConfirmation =
                    !cancelStockMovementConfirmation
                "
                type="button"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div v-else>
            <div class="flex space-x-2">
              <base-button
                color="transparent"
                @click="cancelConfirmation = !cancelConfirmation"
                type="button"
              >
                Batalkan Faktur Penjualan
              </base-button>
              <base-button
                color="transparent"
                v-if="dataPenjualan.faktur_pengemasan"
                @click="
                  cancelStockMovementConfirmation =
                    !cancelStockMovementConfirmation
                "
                type="button"
              >
                Batalkan Faktur Kemasan
              </base-button>
            </div>
          </div>
          <div v-if="dataPenjualan.faktur_pengemasan">
            <template
              v-if="
                dataPenjualan.faktur_pengemasan &&
                !dataPenjualan.faktur_pengemasan.attributes
                  .is_valid_for_shipment
              "
            >
              <base-button @click="onPrintStockMovementReceipt">
                Cetak Surat Tanda Terima
              </base-button>
              <base-button @click="openModalValidation">
                Siap Kirim!
              </base-button>
            </template>
            <base-button
              v-if="
                !getOrder.data.attributes.is_packed &&
                !dataPenjualan.faktur_pengemasan &&
                !dataPenjualan.print_invoice_enabled
              "
              :to="`${indexPath}/${dataPenjualan.id}/pengemasan`"
            >
              Rincian Koli
            </base-button>
            <base-button
              v-if="
                !getOrder.data.attributes.is_packed &&
                !dataPenjualan.faktur_pengemasan &&
                dataPenjualan.print_invoice_enabled
              "
              @click="createInvoiceConfirmation = true"
              id="cetak"
            >
              Cetak Faktur Penjualan
            </base-button>
            <base-button color="transparent" :to="indexPath">
              Kembali
            </base-button>
          </div>
          <div v-else>
            <base-button
              v-if="dataPenjualan.faktur_pengemasan"
              @click="validateConfirmation = true"
            >
              Siap, Kirim!
            </base-button>
            <base-button
              v-if="
                !getOrder.data.attributes.is_packed &&
                !dataPenjualan.print_invoice_enabled
              "
              :to="`${indexPath}/${dataPenjualan.id}/pengemasan`"
            >
              Rincian Koli
            </base-button>
            <base-button
              v-if="
                !getOrder.data.attributes.is_packed &&
                dataPenjualan.print_invoice_enabled
              "
              @click="createInvoiceConfirmation = true"
            >
              Cetak Faktur Penjualan
            </base-button>
            <base-button color="transparent" :to="indexPath">
              Kembali
            </base-button>
          </div>
        </div>
      </div>

      <!-- Detail Koli -->
      <PacketModal
        v-model="openDetail"
        @close="openDetail = false"
        :packet="selectedPacket"
      />

      <ValidationModal
        v-model="createInvoiceConfirmation"
        title="Buat Faktur"
        description="Apakah anda yakin data yang dibuat sudah benar?"
        @submit="createInvoice"
        @close="createInvoiceConfirmation = !createInvoiceConfirmation"
      />

      <ValidationModal
        v-model="validateConfirmation"
        @submit="validate"
        title="Validasi Ke Pengiriman"
        @close="validateConfirmation = !validateConfirmation"
      />
      <!-- MODAL ACC ATASAN-->
      <AccAtasanModal
        @close="() => (warningConfirmation = !warningConfirmation)"
        @validate="validate"
        :visible="warningConfirmation"
        v-model="acc_shipment_reason"
      />

      <!-- MODAL CANCEL CONFIRMATION-->
      <CancelConfirmationModal
        :visible="cancelConfirmation"
        @cancel="cancelOrder"
        v-model="cancel_description"
        @close="cancelConfirmation = !cancelConfirmation"
      />

      <!-- MODAL BATAL FAKTUR PENGEMASAN -->
      <CancelStockMovementModal
        :visible="cancelStockMovementConfirmation"
        @cancel="cancelStockMovement"
        @close="
          cancelStockMovementConfirmation = !cancelStockMovementConfirmation
        "
      />
    </div>
    <RemovePacketModal v-model="removePacketConfirmation" />
    <SuccessModal v-model="success" />
    <BaseErrorModal :message="getError" v-model="failed" />
    <!-- print penjualan -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="getOrder.data.attributes.origin_code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="html2Pdf"
    >
      <PdfContent slot="pdf-content" :order="getOrder" />
    </vue-html2pdf>

    <!-- print pengeluaran -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="getOrder.data.attributes.code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printStockMovementEl"
    >
      <LayoutPrintStockMovement
        v-if="printStockMovementOptions.type === 'stock-movement'"
        slot="pdf-content"
        :elements="{
          packets: false,
          receipt: false,
        }"
        :stock-movement="printStockMovementAttributes"
      />
      <LayoutPrintStockMovementPacket
        v-else-if="printStockMovementOptions.type === 'stock-movement-packet'"
        slot="pdf-content"
        :stock-movement="printStockMovementAttributes"
        :packet="printPacketAttributes"
      />
      <LayoutPrintStockMovementReceipt
        v-else-if="printStockMovementOptions.type === 'stock-movement-receipt'"
        slot="pdf-content"
        :stock-movement="printStockMovementAttributes"
      />
    </vue-html2pdf>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Loading from '@/components/Loading.vue';
import VueHtml2pdf from 'vue-html2pdf';
import PdfContent from '@/layouts/LayoutPrintA4.vue';
import AccAtasanModal from '@/components/pengeluaran/acc-atasan-modal.vue';
import CancelConfirmationModal from '@/components/pengeluaran/cancel-confirmation-modal.vue';
import CancelStockMovementModal from '@/components/pengeluaran/cancel-stock-movement-modal.vue';
import InformasiBarangKeluar from '@/components/pengeluaran/informasi-barang-keluar.vue';
import LayoutPrintStockMovement from '@/layouts/LayoutPrintStockMovement.vue';
import LayoutPrintStockMovementPacket from '@/layouts/LayoutPrintStockMovementPacket.vue';
import LayoutPrintStockMovementReceipt from '@/layouts/LayoutPrintStockMovementReceipt.vue';
import OrderInformation from '@/components/pengeluaran/order-information.vue';
import InformasiKemasan from '@/components/pengeluaran/informasi-kemasan.vue';
import ValidationModal from '@/components/modal/validation-modal.vue';
import BaseErrorModal from '@/components/base/BaseErrorModal';
import SuccessModal from '@/components/modal/success-modal.vue';
import PacketModal from '@/components/packet/PacketModal.vue';
import RemovePacketModal from '@/components/packet/RemovePacketModal.vue';

export default {
  name: 'DetailPengeluaran',
  components: {
    Loading,
    VueHtml2pdf,
    PdfContent,
    AccAtasanModal,
    CancelConfirmationModal,
    CancelStockMovementModal,
    InformasiBarangKeluar,
    LayoutPrintStockMovement,
    LayoutPrintStockMovementReceipt,
    LayoutPrintStockMovementPacket,
    OrderInformation,
    InformasiKemasan,
    ValidationModal,
    BaseErrorModal,
    SuccessModal,
    PacketModal,
    RemovePacketModal,
  },
  props: {
    indexPath: String
  },
  data() {
    return {
      acc_shipment_reason: null,
      is_detail: false,
      is_shipped: false,
      modal: null,
      createInvoiceConfirmation: false,
      cancelConfirmation: false,
      cancelStockMovementConfirmation: false,
      confirmationWarehouse: false,
      validateConfirmation: false,
      warningConfirmation: false,
      removePacketConfirmation: false,
      success: false,
      failed: false,
      openDetail: false,
      openListOrders: false,
      cancel_description: null,
      cancel_warehouse_description: null,
      dataPenjualan: {
        id: '',
        no_penjualan: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        has_warehouse_receipt: false,
        metode: null,
        is_packed: null,
        is_paid: null,
        print_invoice_enabled: null,
        faktur_gudang: [],
        order_details: [],
        faktur_pengemasan: null,
        no_resi: null,
        taken_by: null,
        expedition: null,
      },
      stock_movements: [],
      packet: {},
      selectedPacket: null,
      printStockMovementOptions: {
        type: null,
        packetIndex: null,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataPenjualan.id = this.$route.params.id;
      this.setOrder(this.dataPenjualan.id);
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getStockists: 'stockists/getStockists',
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getError: 'orders/getError',
      getExpeditionsByOffice: 'expeditions/getExpeditionsByOffice',
    }),
    selisihPembayaran() {
      return (
        (this.dataPenjualan.total_penjualan || 0) -
        this.sum(this.metodePembayaran, 'nominalPembayaran')
      );
    },

    currentStockMovement() {
      return this.getIncludedByType(this.getOrder, 'stock-movements').find(
        (stockMovement) => {
          return (
            stockMovement.attributes['movement-type'] === 'main-delivery' &&
            !stockMovement.attributes.is_shipped &&
            !stockMovement.attributes.is_received
          );
        }
      );
    },
    printStockMovementAttributes() {
      const destinationOffice = this.getSingleIncluded(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      );

      return {
        code: this.currentStockMovement
          ? this.currentStockMovement.attributes.code
          : '-',
        date: this.currentStockMovement
          ? dayjs(this.currentStockMovement.attributes.updatedAt).format(
              'll LT'
            )
          : '-',
        order: {
          code: `${this.getOrder.data.attributes.origin_code}/${this.getOrder.data.attributes.destination_code}`,
          destinationOffice: destinationOffice.attributes.code,
          address: destinationOffice.attributes.address,
          phone: destinationOffice.attributes.phone,
        },
        packets: this.currentStockMovement.attributes.packets,
      };
    },
    printPacketAttributes() {
      return this.printStockMovementAttributes.packets[
        this.printStockMovementOptions.packetIndex
      ];
    },
    stockMovement() {
      return this.getOrder.data.relationships['stock-movements'].data[0]
        ? this.getRelationships(
            this.getOrder,
            this.getOrder.data.relationships['stock-movements'].data[0].id
          )
        : null;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      cancelOrderFromWarehouse: 'orders/cancelOrderFromWarehouse',
      updateStockMovement: 'stock_movements/updateStockMovement',
      deletePacket: 'packets/deletePacket',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
    }),
    setOrder(id) {
      this.fetchOrder({
        id: id,
        fields: {
          orders:
            'origin_code,area_code,area_name,updatedAt,createdAt,destination_code,final_price,payment_amount,order_shipment,is_valid_for_shipment,is_packed,is_paid,print_invoice_enabled,warehouse_status,origin-warehouse,destination-warehouse,origin-office,destination-office,stock-movements,order-details,province,city,district,village,shipment_address,order-detail-bonuses',
          offices: 'code,name,address,phone',
          'order-details':
            'product_code,product_name,product_price,bonus_value,point_value,product_qty,product_weight,total_price,total_weight,product_not_packed',
        },
        include:
          'origin-warehouse,destination-warehouse,origin-office,destination-office,stock-movements,order-details,province,city,district,village,order-detail-bonuses',
      }).then((response) => {
        this.dataPenjualan.no_penjualan =
          response.data.data.attributes.origin_code;
        this.dataPenjualan.has_warehouse_receipt =
          response.data.data.attributes.has_warehouse_receipt;
        this.dataPenjualan.origin_office_id =
          response.data.data.relationships['origin-office'].data.id;
        this.dataPenjualan.destination_office_id =
          response.data.data.relationships['destination-office'].data.id;
        this.dataPenjualan.kode_stockist = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.code;
        this.dataPenjualan.nama_pemilik = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.name;
        this.dataPenjualan.total_penjualan =
          response.data.data.attributes.final_price -
          parseInt(response.data.data.attributes.payment_amount);
        this.dataPenjualan.alamat = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.address;
        this.dataPenjualan.order_shipment =
          response.data.data.attributes.order_shipment;
        this.getOrder.data.attributes.is_packed =
          response.data.data.attributes.is_packed;
        this.dataPenjualan.is_paid = response.data.data.attributes.is_paid;
        this.dataPenjualan.print_invoice_enabled =
          response.data.data.attributes.print_invoice_enabled;
        this.dataPenjualan.warehouse_status =
          response.data.data.attributes.warehouse_status;
        this.dataPenjualan.order_details = response.data.data.relationships[
          'order-details'
        ].data.map((relationship) =>
          this.getRelationships(response.data, relationship.id)
        );
        this.dataPenjualan.faktur_pengemasan = response.data.data.relationships[
          'stock-movements'
        ].data[0]
          ? this.getRelationships(
              response.data,
              response.data.data.relationships['stock-movements'].data[0].id
            )
          : null;
        // this.dataPenjualan.faktur_pengemasan = response.data.data.relationships['stock-movements'].data.map((move) => {
        //   return this.getRelationships(response.data, move.id)
        // })
      });
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal(koli) {
      this.openDetail = !this.openDetail;
      this.selectedPacket = koli;
    },
    clearData() {
      this.dataPenjualan = {
        id: '',
        no_penjualan: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        has_warehouse_receipt: false,
        metode: null,
        is_packed: null,
        print_invoice_enabled: null,
        faktur_gudang: [],
        order_details: [],
        faktur_pengemasan: null,
        no_resi: null,
        taken_by: null,
        expedition: null,
      };
      (this.metodePembayaran = [
        {
          jenisPembayaran: null,
          nominalPembayaran: null,
        },
      ]),
        (this.cancel_description = null);
      this.cancel_warehouse_description = null;
      this.stock_movements = null;
      this.selectedPacket = null;
    },
    cancelPayment() {
      const payload = {
        data: {
          type: 'payments',
          id: this.invoice.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updatePayment(payload).then((response) => {
        if (response) {
          this.confirmationPayment = !this.confirmationPayment;
          this.setOrder(this.dataPenjualan.id);
        } else {
          this.failed = true;
        }
      });
    },
    cancelOrder() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            cancel_warehouse_description: this.cancel_description,
          },
        },
      };
      this.cancelOrderFromWarehouse(payload).then((response) => {
        if (response) {
          this.$router.push(this.indexPath);
        } else {
          this.failed = true;
        }
      });
    },
    cancelStockMovement() {
      this.deleteStockMovement({
        id: this.dataPenjualan.faktur_pengemasan.id,
      }).then((response) => {
        if (response) {
          this.cancelStockMovementConfirmation = false;
          this.setOrder(this.dataPenjualan.id);
        }
      });
    },
    // cetak faktur invoice penjualan
    createInvoice() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            print_invoice_enabled: false,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.validateConfirmation = false;
          this.warningConfirmation = false;
          this.createInvoiceConfirmation = false;
          if (this.$route.params.id) {
            this.is_detail = true;
            this.dataPenjualan.id = this.$route.params.id;
            this.setOrder(this.dataPenjualan.id);
          }
          setTimeout(() => {
            this.$refs.html2Pdf.generatePdf();
          }, 0);
        } else {
          this.failed = true;
        }
      });
    },
    sendOrder() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.dataPenjualan.faktur_gudang[0].id,
          attributes: {
            is_shipped: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.$router.push(this.indexPath);
        }
        this.openDetail = false;
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_id: param.product_id,
          product_qty: param.product_not_recieved,
          no_batch: param.no_batch,
          expired_at: param.expired_at,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    removePacket() {
      this.deletePacket({
        id: this.packet.uuid,
      }).then((response) => {
        if (response) {
          this.removePacketConfirmation = false;
          this.setOrder(this.dataPenjualan.id);
        }
      });
    },
    openModalValidation() {
      const totalOrderDetail = this.dataPenjualan.order_details.reduce(
        (prev, current) => prev + current.attributes.product_not_packed,
        0
      );
      const totalBonus = this.getIncludedByType(this.getOrder, 'order-detail-bonuses').reduce(
        (prev, current) => prev + current.attributes.bonus_qty,
        0
      );
      const shipped =
        this.dataPenjualan.faktur_pengemasan.attributes.packets.reduce(
          (prev, current) => {
            const shipped = current.products.reduce(
              (prev, current) => prev + current.shipped_product_qty,
              0
            );

            return prev + shipped;
          },
          0
        );

      if ((totalOrderDetail + totalBonus) === shipped) {
        this.validateConfirmation = true;
      } else {
        this.warningConfirmation = true;
      }
    },
    validate() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.dataPenjualan.faktur_pengemasan.id,
          attributes: {
            is_valid_for_shipment: true,
            acc_shipment_reason: this.acc_shipment_reason,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.validateConfirmation = false;
          this.warningConfirmation = false;

          this.$router.push(this.indexPath);
        }
      });
    },
    redirectToFollowDelivery() {
      this.$router.push('/gudang/susulan/barang-ke-stockist');
    },
    printStockMovement({ type, packetIndex }) {
      this.printStockMovementOptions.type = type;
      this.printStockMovementOptions.packetIndex = packetIndex;

      setTimeout(() => this.$refs.printStockMovementEl.generatePdf(), 0);
    },
    onPrintStockMovement() {
      this.printStockMovement({
        type: 'stock-movement',
      });
    },
    onPrintStockMovementReceipt() {
      this.printStockMovement({
        type: 'stock-movement-receipt',
      });
    },
    onPrintPacket(index) {
      this.printStockMovement({
        type: 'stock-movement-packet',
        packetIndex: index,
      });
    },
  },
};
</script>
