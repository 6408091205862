<template>
  <transition
    name="customFade"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="value"
      style="animation-duration: 0.3s"
      class="fixed inset-0 z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle"
        >
          <form @submit.prevent="removePacket">
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Hapus Koli
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Anda yakin ingin menghapus koli
                    <span class="font-semibold">{{ packet.code }}</span
                    >?
                  </p>
                </div>
              </div>
              <div class="my-6 sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">
                  Berikut daftar barang yang akan terhapus
                </dt>
                <Datatable :paginated="false" :footer="false" class="mt-4">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          KODE BARANG
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          NAMA BARANG
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          JUMLAH
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <tr
                        class="bg-white"
                        v-for="(barang, index) in packet.products"
                        :key="index"
                      >
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        >
                          {{ barang.product_code }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ barang.product_name }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ barang.shipped_product_qty }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </Datatable>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <base-button type="submit"> Ya </base-button>
              <base-button
                color="white"
                @click="removePacketConfirmation = !removePacketConfirmation"
                type="button"
              >
                Tutup
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'RemovePacketModal',
  props: {
    value: {
      type: Boolean,
    },
    packet: {
      type: Object,
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  components: {
    // Your child components here
  },
  methods: {
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
