<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-input label="Filter Status">
          <base-select
            :shadow="false"
            v-model="filter.warehouse_status"
            @change="onFilter"
            :options="[
              {
                label: 'Semua',
                value:
                  'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
              },
              {
                label: 'Diproses Gudang Pengiriman',
                value: 'Diproses Gudang Pengiriman',
              },
              {
                label: 'Persiapan',
                value: 'Persiapan',
              },
              {
                label: 'Diproses Gudang Pengeluaran',
                value: 'Diproses Gudang Pengeluaran',
              },
            ]"
          />
        </base-input>
        <base-input
          :shadow="false"
          placeholder="Cari Kode atau Nama"
          type="search"
          debounce
          label="Cari"
          v-on:native-input="onFilter"
          v-model="filter.search"
        />
      </div>
      <pengeluaran-table @change-page="onChangePage" @click-detail="onClickDetail" :orders="orders" />
    </div>

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex'
import PengeluaranTable from '@/components/pengeluaran/v2/pengeluaran-table.vue'

export default {
  mixins: [requestMixin],
  components: {
    PengeluaranTable
  },
  data() {
    return {
      loading: false,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
        warehouse_status: 'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser'
    }),
  },
  methods: {
    onChangePage(page) {
      this.loadOrders({
        ...page,
      });
    },
    onFilter() {
      this.loadOrders();
    },
    onClickDetail(simpleOrder) {
      this.$router.push({
        name: 'gudang.pengeluaran.barang-ke-stockist-dikirim.detail',
        params: {
          id: simpleOrder.relationships.order.data.id
        }
      })
    },
    async loadOrders(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 4,
          'filter[order_shipment]': 'delivery',
          'filter[origin_office_id]': this.me.office_id,
          'filter[search]': this.filter.search,
          'filter[warehouse_status]': this.filter.warehouse_status,
          'include': 'destination-office,destination-warehouse,origin-warehouse,order',
          'fields[simple-orders]': 'origin_code,updatedAt,destination-office,destination-warehouse,origin-warehouse,order',
          'fields[offices]': 'code,name',
          'fields[warehouses]': 'code',
          'fields[orders]': 'area_name,grand_total_price_after_ppn,warehouse_status',
          ...params,
        },
      });

      if (!error) {
        this.orders = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
