var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto px-4 pb-12 sm:px-4 lg:px-8"},[_c('BaseCard',{attrs:{"title":"Pengeluaran Barang ke Kantor Pelayanan"}},[_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('div',{staticClass:"relative mt-1 rounded-md shadow-sm"},[_c('base-select',{attrs:{"options":[
            {
              label: 'Semua',
              value:
                'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman',
            },
            {
              label: 'Diproses Gudang Pengiriman',
              value: 'Diproses Gudang Pengiriman',
            },
            {
              label: 'Persiapan',
              value: 'Persiapan',
            },
            {
              label: 'Diproses Gudang Pengeluaran',
              value: 'Diproses Gudang Pengeluaran',
            },
          ]},on:{"change":_vm.filterByShipped},model:{value:(_vm.order_status),callback:function ($$v) {_vm.order_status=$$v},expression:"order_status"}})],1),_c('base-search',{on:{"input":_vm.debounceSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"my-2 flex sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('PengeluaranTable',{attrs:{"view-as":"kantor-pelayanan"},on:{"sort":_vm.handleSort,"change":_vm.onPageChangeOrder,"click-row":_vm.redirectToDetail}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }